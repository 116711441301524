import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const DuraguardSabseKhaasSarpanch = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Successfully Concludes Duraguard “Sabse Khaas Sarpanch” Campaign in West Madhya Pradesh  | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Successfully Concludes Duraguard “Sabse Khaas Sarpanch” Campaign in West Madhya Pradesh" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/duraguard-sabse-khaas-sarpanch"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/SAbse%20Khaas%20Sarpanch.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Successfully Concludes Duraguard “Sabse Khaas Sarpanch” Campaign in West Madhya Pradesh</h2>
         
          <p className='onetxt'><strong>Madhya Pradesh, 30 September 2024: Nuvoco Vistas Corp. Ltd.,</strong> India's fifth-largest cement group, has successfully concluded its flagship rural outreach initiative, <b>"Sabse Khaas Sarpanch," </b>as a part of the ongoing brand campaign, <b>"Seedhi Baat Hai, Duraguard Khaas Hai”</b></p>

          <p>The final felicitation ceremony recognized more than 150 exceptional sarpanchs from West Madhya Pradesh, acknowledging their outstanding contributions to village development. This unique brand campaign unfolded through three key events, with the finale marking the culmination of a campaign that reached over 4000 villages, engaged 2500 sarpanchs, and honored 150 top performers.</p>

          <p>Launched in February 2024, the "Sabse Khaas Sarpanch" initiative was designed to establish a direct brand connection between Duraguard Cement and rural development. The sarpanchs were celebrated as the true heroes who spearhead significant changes in their communities. The campaign's extensive reach across West MP fostered brand visibility, recall, and awareness, establishing Duraguard Cement as a trusted partner in village transformation.</p>

          <p>Through this activation, Nuvoco has not only bolstered its brand presence but also initiated long-lasting business relationships. Several recognized sarpanchs have now become sub-dealers and dealers.</p>

          <p><strong>Chirag Shah, Head of Marketing, Innovation and Sales Excellence, Nuvoco Vistas Corp. Ltd., stated,</strong> "We are thrilled by the overwhelming response to the 'Sabse Khaas Sarpanch' initiative, which showcases the impactful stories of Sarpanches who are at the forefront of village development. This initiative, part of our 'Duraguard Khaas Hai' campaign, underscores our commitment to rural development and acknowledges the remarkable efforts of these village leaders in driving transformative change within their communities. Not only has this campaign enhanced brand recall, but it has also opened new avenues for business partnerships. We look forward to deepening our engagement with these local heroes as we continue to strengthen our presence in the rural market."</p>
          <p>With this powerful initiative, Nuvoco’s Duraguard Cement continues to build stronger ties with India's rural heartland, fostering both community development and brand growth.</p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building materials company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest cement group in terms of capacity (25 MMTPA), with INR 10,733 crore in total revenue from operations in FY24. It is among the leading players in East India. Nuvoco offers a diversified business portfolio in three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes - Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses a pan-India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium (Rourkela), Aquatic Gallery Science City (Ahmedabad), Metro Railway (Delhi, Jaipur, Noida and Mumbai), and among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ brands, comprises construction chemicals, tile adhesives, wall putty and cover blocks. Through the NABLaccredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on to <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default DuraguardSabseKhaasSarpanch