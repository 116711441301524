import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const SuccessfulResolutionApplicant = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>NUVOCO Vistas emerges as the Successful Resolution Applicant (SRA) in relation to the Corporate Insolvency Resolution Process of Vadraj Cement Limited (VCL) | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="NUVOCO Vistas emerges as the Successful Resolution Applicant (SRA) in relation to the Corporate Insolvency Resolution Process of Vadraj Cement Limited (VCL)" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/successful-resolution-applicant"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Final%20-%20Press%20Release%20-%20VCL%20-%2006Jan25%20-%20Post%20LOI.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: NUVOCO Vistas emerges as the Successful Resolution Applicant (SRA) in relation to the Corporate Insolvency Resolution Process of Vadraj Cement Limited (VCL)</h2>

          <ul>
            <li>Post-transaction* approval, Nuvoco’s installed cement capacity will increase by over 20%</li>
            <li>The consolidated capacity will reach 31 MMTPA, ensuring Nuvoco’s position as the fifthlargest cement group in India</li>
            <li>Enhanced geographical reach, with nearly 40% of total capacity in the North and West regions</li>
            <li>Overall investment, including refurbishment of assets, will be more cost-effective than comparable greenfield or brownfield expansions</li>
            <li>Represents a strategic <b>‘value-buy’</b> delivering substantial growth at one of the lowest cost perton in recent times</li>
            <li>Transaction* aligns with Nuvoco’s stated objective to ramp up capacity by the end of Fiscal’25</li>
          </ul>
         
          <p className='onetxt'><strong>Mumbai, January 06, 2025:</strong> Nuvoco Vistas Corp. Ltd., India’s fifth-largest cement group and a leading player in East India, has emerged as the SRA of Vadraj Cement Limited (VCL), currently undergoing Corporate Insolvency Resolution Process. The Resolution Plan submitted by Nuvoco has been approved by the Committee of Creditors (‘CoC’), and a Letter of Intent (LOI) has been issued.</p>

          <p>The transaction will be implemented by Vanya Corporation Private Limited, a wholly owned subsidiary of Nuvoco Vistas Corp. Ltd. Nuvoco intends to fund the transaction without a significant rise in its consolidated debt levels. A phased investment will be spread over 15 months towards the refurbishment of assets and to drive operational improvements across the VCL plants. The estimated target date to commence production is around Q3 FY27, subject to the Hon’ble National Company Law Tribunal (NCLT) approvals for the Resolution Plan.</p>

          <p>The Company anticipates significant benefits from this deal. The existing facilities include a 3.5 MMTPA (~10,000 TPD) clinker unit in Kutch, Gujarat, and a 6 MMTPA grinding unit in Surat, Gujarat. </p>

        <p>Additionally, VCL owns high-quality limestone reserves, ensuring a consistent and sustainable supply of raw materials for future production. The captive jetty in Kutch further enhances logistical efficiency. With this transaction, Nuvoco’s total cement production capacity is set to increase to approx.. 31 MMTPA, distributed as 19 MMTPA in the East, 6 MMTPA in the North, and 6 MMTPA in the West, consolidating its position as the fifth-largest cement group in India for long-term.</p>

          <p>The transaction, once consummated, is anticipated to foster substantial synergies with Nuvoco's existing manufacturing facilities in Nimbol and Chittorgarh, Rajasthan, enabling enhanced operational efficiency. This will drive logistics optimisation, streamline operations, and improve competitiveness, providing the Company with better market access and a strengthened supply chain across key regions.</p>

          <p><b>Note*:</b> The implementation of the resolution plan is subject to the terms of the LOI and requisite approvals from the NCLT</p>
          <p>On this momentous occasion, Mr. Jayakumar Krishnaswamy, Managing Director, Nuvoco Vistas Corp. Ltd., commented, “This deal is a pivotal moment in Nuvoco’s journey, consolidating our position as the fifth-largest player in the Indian Cement Industry and further strengthening our market dominance. It complements our existing operations perfectly, expanding our geographic reach and operational capabilities. This strategic investment will enhance our portfolio, diversify our offerings and enable us to deliver greater value and superior service to our customers in a competitive and dynamic business landscape.”</p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building materials company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest cement group in terms of capacity (25 MMTPA), with INR 10,733 crore in total revenue from operations in FY24. It is among the leading players in East India. Nuvoco offers a diversified business portfolio in three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes - Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses a pan-India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium (Rourkela), Aquatic Gallery Science City (Ahmedabad), Metro Railway (Delhi, Jaipur, Noida and Mumbai), and among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ brands, comprises construction chemicals, tile adhesives, wall putty and cover blocks. Through the NABLaccredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on to <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default SuccessfulResolutionApplicant