import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const IntroducesZeroMTileAdhesiveT5 = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Introduces Zero M Tile Adhesive T5 - Redefining Durability and Versatility in Tile Fixing | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Introduces Zero M Tile Adhesive T5 - Redefining Durability and Versatility in Tile Fixing" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/unveils-nuvo-setu-app"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Press%20Release%20_%20TILE%20ADHESIVE%20T5%20-%20Nuvoco%20Vistas%20FINAL.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Introduces Zero M Tile Adhesive T5 - Redefining Durability and Versatility in Tile Fixing</h2>
         
          <p className='onetxt'><strong>Mumbai, November 20, 2024:</strong> Nuvoco Vistas Corp. Ltd., India's fifth-largest cement company, has launched the unique Nuvoco Zero M Tile Adhesive T5. This two-component epoxy-based polyurethane (PU) adhesive is designed to offer unmatched performance for fixing all types of tiles and stones on a variety of substrates in both interior and exterior applications, setting new benchmarks in the Building Material industry.</p>

          <p>The Nuvoco Zero M Tile Adhesive T5 sets new standards in versatility and durability for specialized installations. Its unique formulation makes it possible to easily fix tiles and stones to a variety of surfaces, including cement sheet, Bison panels, Wood, Gypsum Board, PVC, and metal, as well as ceramic and vitrified tiles, natural stone, glass mosaics, and porcelain.</p>

          <p>The adhesive conforms to IS 15477:2019 Type-5 'T' S2 and EN 12004:2017 - "R2T", ensuring adherence to the highest industry standards.</p>

          <p>A standout feature of this adhesive is its ability to withstand adverse environmental conditions. It is resistant to chemicals, high temperatures, impacts, and vibrations, ensuring longevity and preventing bond failures. It’s flexible yet robust composition accommodates minor movements in substrates without cracking or delamination, making it a reliable solution for long-term installations.</p>

        <p><strong>Chirag Shah, Head of Marketing, Innovation, and Sales Excellence at Nuvoco Vistas Corp. Ltd said,</strong> <i>“The Nuvoco Zero M Tile Adhesive T5 is a testament to our focus on innovation and excellence. With its unmatched versatility and superior performance, it addresses the growing demand for reliable and durable building materials in the construction industry. This launch reflects our commitment to delivering solutions that meet the evolving needs of our customers.”</i></p>
        
        <p>The Nuvoco Zero M Tile Adhesive T5 comes in a convenient 4 kg bucket, comprising 3.65 kg of resin and 0.35 kg of hardener, ensuring ease of use. It is readily available across key regions in North, West, and East India, including Punjab, Haryana, Delhi NCR, Gujarat, Maharashtra, Rajasthan, Chhattisgarh, West Bengal, Jharkhand, Madhya Pradesh, andOdisha.</p>

      <p><strong>About Nuvoco Vistas</strong></p>
      <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building materials company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest cement group in terms of capacity (25 MMTPA), with INR 10,733 crore in total revenue from operations in FY24. It is among the leading players in East India. Nuvoco offers a diversified business portfolio in three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes - Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses a pan-India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium (Rourkela), Aquatic Gallery Science City (Ahmedabad), Metro Railway (Delhi, Jaipur, Noida and Mumbai), and among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ brands, comprises construction chemicals, tile adhesives, wall putty and cover blocks. Through the NABLaccredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on to <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default IntroducesZeroMTileAdhesiveT5