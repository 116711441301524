import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import { Helmet } from "react-helmet";

import banner from "../assets/images/corporate_governance.png";
import bannermobile from "../assets/images/corporate-governance-mobile.jpg";
import aboutBannerLogo from "../assets/images/corporateicon.png";
import sortby from "../assets/images/sortby.png";

import { corporateApi, corporatesearchApi } from "../redux";
import { connect } from "react-redux";
import PdfCard from "../common/pdfCard";
import { imgUrl } from "../redux/config";
import img1 from "../assets/images/bod03.jpg";
import img2 from "../assets/images/bod04.jpg";
import img6 from "../assets/images/Shishir-Desai.png";
import img3 from "../assets/images/bod02.jpg";
import img4 from "../assets/images/img4.png";
import img5 from "../assets/images/bod05.jpg";
import MOMPDF from "../assets/pdf/COI-MOA-AOA-certified.pdf"
let IsData = false;
let status = null;
const CorporateGovernance = ({
  corporateApi,
  corporate,
  corporatesearchApi,
  corporatesearch
}) => {
  useEffect(() => {
    corporateApi(1);
    corporatesearchApi(1, 8);
  }, []);

  if (Object.keys(corporate).length) {
    status = corporate.data.status;
    if (corporate.data.status === 200) {
      const cropData = { ...corporate.data };
      var category = JSON.parse(cropData.corp_cat);
      if (category.length > 0) {
        //const bod = category.splice(6,1);
        //category.unshift(bod[0]);
        //console.log('unshift---' + JSON.stringify(category));
      }
      var corpyear = JSON.parse(cropData.corp_year);
      var cropmetting = JSON.parse(cropData.corp_meet);
      var cropPolicies = JSON.parse(cropData.corp_policy);
    }
  }

  const [IsListing, setListingType] = useState(1);
  const [company, setCompany] = useState("NVCL");
  const [IsCategory, setCategory] = useState(8);
  const [IsYear, setYear] = useState(7);
  const [IsMetting, setMetting] = useState(1);
  const [IsPolicie, setPolicie] = useState(1);
  const [pdfList, setPdfList] = useState([]);
  const [sortBy, setSort] = useState(1);

  const [IsCommitte, setCommitte] = useState(1);
  const [IsCompanyCommitte, setCompanyCommitte] = useState(1);
  const [IsNVCLCommitte, setNVCLCommitte] = useState(2);

  const setListing = (id) => {
    setListingType(id);
    corporateApi(id);
    
    if(id === 1){
        corporatesearchApi(1, 5, "", "", id);
    }else{
        corporatesearchApi(1, 6, "", "", id);
    }
    
  };

  const setCategoryData = (val) => {
    setCategory(val.id);
    switch (val.id) {
      case 3:
        corporatesearchApi(val.id, IsYear, IsMetting, "", IsListing);
        break;
      case 5:
        corporatesearchApi(val.id, "", "", IsPolicie);
        break;
      default:
        corporatesearchApi(val.id, IsYear, "", "", IsListing);
    }
  };

  const change = (type) => {
    setYear(type.target.value);
    if (IsCategory === 3) {
      corporatesearchApi(IsCategory, type.target.value, IsMetting, "", IsListing);
    } else {
      corporatesearchApi(IsCategory, type.target.value, "", "", IsListing);
    }
  };

  const changeMetting = (type) => {
    setMetting(type.target.value);
    corporatesearchApi(IsCategory, IsYear, type.target.value, "", IsListing);
  };

  const changePolicie = (type) => {
    setPolicie(type.target.value);
    corporatesearchApi(IsCategory, "", "", type.target.value);
  };

  const changeCommitte = (type) => {
    setCommitte(parseInt(type.target.value));
  };
  const changeCompanyCommitte = (type) => {
    setCompanyCommitte(parseInt(type.target.value));
  };
  const changeNVCLCommitte = (type) => {
    setNVCLCommitte(parseInt(type.target.value));
  };

  const sortData = (data, t) => {
    let copy;
    if (t === 1) {
      copy = [...data];
    } else {
      copy = [...pdfList];
    }

    sortBy === 1
      ? copy.sort((a, b) =>
          new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
        )
      : copy.sort((a, b) =>
          new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
        );
    setSort(sortBy === 1 ? 2 : 1);
    setPdfList(copy);
  };

  if (corporatesearch.IsSuccess === 1) {
    IsData = true;
    corporatesearch.IsSuccess = 0;
    const pdfData = { ...corporatesearch.data };
    sortData(JSON.parse(pdfData.corp_doc), 1);
  }

  return (
    <>
      {(IsData && (
        <div className="crop_gov">
          <Helmet>
            <title>Corporate Governance | Nuvoco Vistas Corp Ltd.</title>
            <meta name="keywords" description="" />
            <meta
              name="description"
              content="Nuvoco believes strongly in all parameters of Corporate Governance To know all about our Corporate Governance policies implemented across the company browse on the relevant sections"
            />
          </Helmet>
          <div className="banner text-center position-relative">
            <img className="desktopbannerinner" src={banner} alt="" />
            <img className="mobilebannerinner" src={bannermobile} alt="" />
            <article className="position-absolute text-center">
              <span className="d-flex justify-content-center align-items-center mx-auto">
                <img src={aboutBannerLogo} alt="" />
              </span>
              <h1 className="text-uppercase">CORPORATE GOVERNANCE</h1>
            </article>
            <div className="breadouter">
              <div className="container">
                <Breadcrumb className="mb-0 py-2">
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Corporate Governance</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
          <Container className="py-5 text-center">
            <div className="mb-4 prepost_btn">
              <Button
                variant="success"
                onClick={() => setListing(1)}
                className={IsListing === 1 ? "active btn_lg" : "btn_lg"}
              >
                <span>Pre-Listing</span>
              </Button>
              <Button
                variant="success"
                onClick={() => setListing(2)}
                className={IsListing === 2 ? "active btn_lg" : "btn_lg"}
              >
                <span>Post-Listing</span>
              </Button>
            </div>

            <Button variant="success" className="active btn_lg d-none" href="#">
              Board Committees
            </Button>

            {category &&
              category.map((val, i) => {
                console.log(">>>>", val)
                return (
                  <Button
                    key={i}
                    onClick={() => setCategoryData(val)}
                    className={
                      val.id === IsCategory ? "active btn_lg" : "btn_lg"
                    }
                    variant="success"
                  >
                    {val.category}
                  </Button>
                );
              })}

            {IsListing === 2 && (
              <>
              <Button
                variant="success"
                className="btn_lg"
                href="https://admin.nuvoco.com/public/WhistleblowerPolicy/Whistle_Blower_Policy.pdf"
                target="_blank"
              >
                Whistleblower Policy
              </Button>
              <Button
              variant="success"
              className="btn_lg"
              href={MOMPDF}
              target="_blank"
            >
              MOA & AOA
            </Button>
            </>
            )}

            {status === 200 && IsCategory <= 7 && (
              <>
                <div className="sortBy mb-2">
                  <Button
                    variant="success"
                    onClick={sortData}
                    className={
                      sortBy === 1 ? "ml-2 read_more" : "ml-2 read_more active"
                    }
                  >
                    <img src={sortby} alt="" /> Sort By
                  </Button>
                </div>
                <div className="selectbx d-flex align-items-center justify-content-center mb-2 mt-4 pb-4">
                  {((IsCategory <= 4 || IsCategory === 6 || IsCategory === 7) &&
                    corpyear && (
                      <Form.Select
                        onChange={change}
                        aria-label="Default select example"
                      >
                        {corpyear.map((val, i) => {
                          return (
                            <option value={val.id} key={i}>
                              {val.year}
                            </option>
                          );
                        })}
                      </Form.Select>
                    )) ||
                    ""}

                  {(IsCategory === 3 && cropmetting && (
                    <Form.Select
                      onChange={changeMetting}
                      className="ml-2"
                      aria-label="Default select example"
                    >
                      {cropmetting.map((val, i) => {
                        return (
                          <option value={val.id} key={i}>
                            {val.board_meeting}
                          </option>
                        );
                      })}
                    </Form.Select>
                  )) ||
                    ""}

                  {(IsCategory === 5 && cropmetting && (
                    <Form.Select
                      onChange={changePolicie}
                      className="ml-2"
                      aria-label="Default select example"
                    >
                      {cropPolicies.map((val, i) => {
                        return (
                          <option value={val.id} key={i}>
                            {val.policie}
                          </option>
                        );
                      })}
                    </Form.Select>
                  )) ||
                    ""}
                </div>
                {pdfList.length > 0 && (
                  <div className="per_hig">
                    <div className="card_round mb-5">
                      <Row className="justify-content-center mb-3 ">
                        {pdfList.map((pdf, i) => {
                          return <PdfCard bd={pdf} uri={imgUrl} key={i} />;
                        })}
                      </Row>
                    </div>
                  </div>
                )}
              </>
            )}

            {IsCategory === 8 && (
              <div className="bord_of_directors">
                {company === '' &&(
                <div className="selectbx d-flex align-items-center justify-content-center mb-2 mt-4 pb-4">
                  <Form.Select
                    onChange={changeCommitte}
                    aria-label="Default select example"
                  >
                    <option value="1">Audit Committee</option>
                    <option value="2">
                      Stakeholders' Relationship Committee
                    </option>
                    <option value="3">
                      Nomination & Remuneration Committee
                    </option>
                    <option value="4">
                      Corporate Social Responsibility Committee
                    </option>
                    <option value="5">Risk Management Committee</option>
                  </Form.Select>
                </div>
                )}
                <div>
                {IsListing === 2 && (
                  <>
                <Button
                   onClick={() => setCompany("NVCL")}
                    className={company == "NVCL" ? "active btn_lg" : "btn_lg"}
                    variant="success"
                  >
                    Nuvoco Vistas Corp. Ltd.
                  </Button>
                  <Button
                    onClick={() => setCompany("NVL")}
                    className={company == "NVL" ? "active btn_lg" : "btn_lg"}
                    variant="success"
                  >
                    NU Vista Limited
                  </Button>
                  </>
                  )}
                 {company === 'NVL' && (
                    <div className="selectbx d-flex align-items-center justify-content-center mb-2 mt-4 pb-4">
                      <Form.Select
                        onChange={changeCompanyCommitte}
                        aria-label="Default select example"
                      >
                        <option value="1">Audit Committee</option>
                        <option value="3">
                          Nomination & Remuneration Committee
                        </option>
                        <option value="4">
                          Corporate Social Responsibility Committee
                        </option>
                      </Form.Select>
                    </div>
                 )}
                {company === 'NVCL' && (
                    <div className="selectbx d-flex align-items-center justify-content-center mb-2 mt-4 pb-4">
                      <Form.Select
                        onChange={changeNVCLCommitte}
                        aria-label="Default select example"
                      >
                        {/* <option value="1">Composition of Board of Directors </option> */}
                        <option value="2">Audit Committee </option>
                        <option value="3">Nomination and Remuneration Committee </option>
                        <option value="4">Corporate Social Responsibility Committee </option>
                        <option value="5">Stakeholders Relationship Committee </option>
                        <option value="6">Risk Management Committee</option>
                      </Form.Select>
                    </div>
                 )}
                </div>
               
                  <div className="card_round">
                    {company === 'NVCL' && IsNVCLCommitte === 1 && (
                      <Row>
                        <Col md={4}>
                          <Card className="wow fadeInUp" data-wow-delay="0.5s">
                            <Card.Img variant="top" src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/Hiren.png" />
                            <Card.Body>
                              <div>
                                <Card.Title>HIREN PATEL</Card.Title>
                                <p>CHAIRMAN</p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img3} />
                          <Card.Body>
                            <div>
                              <Card.Title>KAUSHIKBHAI PATEL</Card.Title>
                              <p>NON-EXECUTIVE DIRECTOR</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img1} />
                          <Card.Body>
                            <div>
                              <Card.Title>BHAVNA DOSHI</Card.Title>
                              <p>INDEPENDENT DIRECTOR AND WOMEN DIRECTOR</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img5} />
                          <Card.Body>
                            <div>
                              <Card.Title>ACHAL BAKERI</Card.Title>
                              <p>INDEPENDENT DIRECTOR</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img6} />
                          <Card.Body>
                            <div>
                              <Card.Title>SHISHIR DESAI</Card.Title>
                              <p>INDEPENDENT DIRECTOR</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img4} />
                          <Card.Body>
                            <div>
                              <Card.Title>JAYAKUMAR KRISHNASWAMY</Card.Title>
                              <p>MANAGING DIRECTOR</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                    {company === 'NVCL' && (IsNVCLCommitte === 2  || IsNVCLCommitte === 3) && (
                      <Row>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img1} />
                          <Card.Body>
                            <div>
                              <Card.Title>BHAVNA DOSHI</Card.Title>
                              <p>CHAIRPERSON</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img6} />
                          <Card.Body>
                            <div>
                              <Card.Title>SHISHIR DESAI</Card.Title>
                              <p>MEMBER</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img3} />
                          <Card.Body>
                            <div>
                              <Card.Title>KAUSHIKBHAI PATEL</Card.Title>
                              <p>MEMBER</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                    {company === 'NVCL' && IsNVCLCommitte === 4 && (
                      <Row>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img1} />
                          <Card.Body>
                            <div>
                              <Card.Title>BHAVNA DOSHI</Card.Title>
                              <p>CHAIRPERSON</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img3} />
                          <Card.Body>
                            <div>
                              <Card.Title>KAUSHIKBHAI PATEL</Card.Title>
                              <p>MEMBER</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img4} />
                          <Card.Body>
                            <div>
                              <Card.Title>JAYAKUMAR KRISHNASWAMY</Card.Title>
                              <p>MEMBER</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                    {company === 'NVCL' && IsNVCLCommitte === 5 && (
                      <Row>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img3} />
                          <Card.Body>
                            <div>
                              <Card.Title>KAUSHIKBHAI PATEL</Card.Title>
                              <p>CHAIRPERSON</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img5} />
                          <Card.Body>
                            <div>
                              <Card.Title>ACHAL BAKERI</Card.Title>
                              <p>MEMBER</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img4} />
                          <Card.Body>
                            <div>
                              <Card.Title>JAYAKUMAR KRISHNASWAMY</Card.Title>
                              <p>MEMBER</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                    {company === 'NVCL' && IsNVCLCommitte === 6 && (
                      <Row>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img3} />
                          <Card.Body>
                            <div>
                              <Card.Title>KAUSHIKBHAI PATEL</Card.Title>
                              <p>CHAIRPERSON</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img1} />
                          <Card.Body>
                            <div>
                              <Card.Title>BHAVNA DOSHI</Card.Title>
                              <p>MEMBER</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img4} />
                          <Card.Body>
                            <div>
                              <Card.Title>JAYAKUMAR KRISHNASWAMY</Card.Title>
                              <p>MEMBER</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img
                            variant="top"
                            src={
                              imgUrl +
                              "public/ManagementTeamDetails/management11.a54d370c.jpg"
                            }
                          />
                          <Card.Body>
                            <div>
                              <Card.Title>MANEESH AGRAWAL</Card.Title>
                              <p>MEMBER</p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>

                      </Row>
                    )}
                    
                    {/* ------------------------------------------ */}
                    {/* {company === 'NVCL' && (
                      <Row>
                        <Col md={4}>
                          <Card className="wow fadeInUp" data-wow-delay="0.5s">
                            <Card.Img variant="top" src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/Hiren.png" />
                            <Card.Body>
                              <div>
                                <Card.Title>HIREN PATEL</Card.Title>
                                <p>CHAIRMAN AND A NON-EXECUTIVE DIRECTOR</p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img3} />
                          <Card.Body>
                            <div>
                              <Card.Title>KAUSHIKBHAI PATEL</Card.Title>
                              <p>NON-EXECUTIVE DIRECTOR</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img1} />
                          <Card.Body>
                            <div>
                              <Card.Title>BHAVNA DOSHI</Card.Title>
                              <p>INDEPENDENT DIRECTOR</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img6} />
                          <Card.Body>
                            <div>
                              <Card.Title>SHISHIR DESAI</Card.Title>
                              <p>INDEPENDENT DIRECTOR</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img5} />
                          <Card.Body>
                            <div>
                              <Card.Title>ACHAL BAKERI</Card.Title>
                              <p>INDEPENDENT DIRECTOR</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img4} />
                          <Card.Body>
                            <div>
                              <Card.Title>JAYAKUMAR KRISHNASWAMY</Card.Title>
                              <p>MANAGING DIRECTOR</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )} */}
                  </div>
                  <div className="card_round">
                  {company === 'NVL' && IsCompanyCommitte === 1 && (
                    <Row>
                      <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/manan.png" />
                          <Card.Body>
                            <div>
                              <Card.Title>MANAN NUTANBHAI SHAH</Card.Title>
                              <p>CHAIRMAN</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/mgmt2.png" />
                          <Card.Body>
                            <div>
                              <Card.Title>SHRUTA SANGHAVI</Card.Title>
                              <p>MEMBER</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                      <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/madhumitabasu.png" />
                          <Card.Body>
                            <div>
                              <Card.Title>MADHUMITA BASU</Card.Title>
                              <p>MEMBER</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                    </Row>

                  )}

                  {company === 'NVL' && IsCompanyCommitte === 3 && (
                    <Row>
                        <Col md={4}>
                          <Card className="wow fadeInUp" data-wow-delay="0.5s">
                            <Card.Img variant="top" src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/mgmt2.png" />
                            <Card.Body>
                              <div>
                                <Card.Title>SHRUTA SANGHAVI</Card.Title>
                                <p>CHAIRPERSON</p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="wow fadeInUp" data-wow-delay="0.5s">
                            <Card.Img variant="top" src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/manan.png" />
                            <Card.Body>
                              <div>
                                <Card.Title>MANAN NUTANBHAI SHAH</Card.Title>
                                <p>MEMBER</p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/madhumitabasu.png" />
                          <Card.Body>
                            <div>
                              <Card.Title>MADHUMITA BASU</Card.Title>
                              <p>MEMBER</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                    </Row>
                  )}
                  {company === 'NVL' && IsCompanyCommitte === 4 && (
                    <Row>
                      <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img4} />
                          <Card.Body>
                            <div>
                              <Card.Title>JAYAKUMAR KRISHNASWAMY</Card.Title>
                              <p>CHAIRMAN</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="wow fadeInUp" data-wow-delay="0.5s">
                            <Card.Img variant="top" src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/mgmt2.png" />
                            <Card.Body>
                              <div>
                                <Card.Title>SHRUTA SANGHAVI</Card.Title>
                                <p>MEMBER</p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="wow fadeInUp" data-wow-delay="0.5s">
                            <Card.Img variant="top" src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/manan.png" />
                            <Card.Body>
                              <div>
                                <Card.Title>MANAN NUTANBHAI SHAH</Card.Title>
                                <p>MEMBER</p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/madhumitabasu.png" />
                          <Card.Body>
                            <div>
                              <Card.Title>MADHUMITA BASU</Card.Title>
                              <p>MEMBER</p>
                            </div>
                          </Card.Body>
                          </Card>
                        </Col>
                    </Row>
                  )}

                  </div>
                    




                  <div className="card_round" style={{display:"none"}}>
                    <Row>
                      <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img3} />
                          <Card.Body>
                            <div>
                              <Card.Title>KAUSHIKBHAI PATEL</Card.Title>
                              <p>CHAIRMAN AND NON EXECUTIVE DIRECTOR</p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>

                      
                      
                    </Row>
                  </div>
                
                  <div className="card_round" style={{display:"none"}}>
                    <Row>
                    
                      <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img1} />
                          <Card.Body>
                            <div>
                              <Card.Title>BHAVNA DOSHI</Card.Title>
                              <p>CHAIRMAN AND INDEPENDENT DIRECTOR</p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4} style={company == "NVCL" ? {display:'block'} : {display:'none'}}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img6} />
                          <Card.Body>
                            <div>
                              <Card.Title>SHISHIR DESAI</Card.Title>
                              <p>INDEPENDENT DIRECTOR</p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img3} />
                          <Card.Body>
                            <div>
                              <Card.Title>KAUSHIKBHAI PATEL</Card.Title>
                              <p>NON EXECUTIVE DIRECTOR</p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                
                  <div className="card_round" style={{display:"none"}}>
                    <Row>
                      <Col md={4}>
                      <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img1} />
                          <Card.Body>
                            <div>
                              <Card.Title>BHAVNA DOSHI</Card.Title>
                              <p>CHAIRPERSON AND INDEPENDENT DIRECTOR</p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img3} />
                          <Card.Body>
                            <div>
                              <Card.Title>KAUSHIKBHAI PATEL</Card.Title>
                              <p>NON EXECUTIVE DIRECTOR</p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img4} />
                          <Card.Body>
                            <div>
                              <Card.Title>JAYAKUMAR KRISHNASWAMY</Card.Title>
                              <p>MANAGING DIRECTOR</p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                
                  <div className="card_round" style={{display:"none"}}>
                    <Row>
                      <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img3} />
                          <Card.Body>
                            <div>
                              <Card.Title>KAUSHIKBHAI PATEL</Card.Title>
                              <p>CHAIRPERSON AND NON EXECUTIVE DIRECTOR</p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img1} />
                          <Card.Body>
                            <div>
                              <Card.Title>BHAVNA DOSHI</Card.Title>
                              <p>INDEPENDENT DIRECTOR</p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4} style={company == "NVCL" ? {display:'block'} : {display:'none'}}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img variant="top" src={img4} />
                          <Card.Body>
                            <div>
                              <Card.Title>JAYAKUMAR KRISHNASWAMY</Card.Title>
                              <p>MANAGING DIRECTOR</p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                          <Card.Img
                            variant="top"
                            src={
                              imgUrl +
                              "public/ManagementTeamDetails/management11.a54d370c.jpg"
                            }
                          />
                          <Card.Body>
                            <div>
                              <Card.Title>MANEESH AGRAWAL</Card.Title>
                              <p>CHIEF FINANCIAL OFFICER</p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                
              </div>
            )}

            {(status === 404 || pdfList.length === 0) && IsCategory < 8 && (
              <Alert variant="danger">
                <Alert.Heading>Data Not Available</Alert.Heading>
              </Alert>
            )}
          </Container>
        </div>
      )) ||
        ""}
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    corporate: state.corporateR.corporate,
    corporatesearch: state.corporateR.corporatesearch,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    corporateApi: function (type) {
      dispatch(corporateApi(type));
    },
    corporatesearchApi: function (cid, yid, mid, pid, listType) {
      dispatch(corporatesearchApi(cid, yid, mid, pid, listType));
    },
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(CorporateGovernance);
