import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const FinancialResultQ2FY25 = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Financial result Q2 FY25 | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="NUVOCO Vistas announces its financial results for Q2 FY25" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/financial-result-q2-fy25"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Final%20Release%20-%20Q2%20(1).pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>NUVOCO Vistas announces its financial results for Q2 FY25</h2>
          
          <ul>
            <li>Consolidated revenue from operation stood at Rs. 2,269 crores</li>
            <li>Consolidated EBITDA stood at Rs. 229 crores</li>
          </ul>
          <p className='onetxt'>
            <strong>Mumbai, October 23, 2024: </strong>Nuvoco Vistas Corp. Ltd., a leading building materials Company in India, announced its unaudited financial results for the quarter ended September 30, 2024. With 25 MMTPA of combined installed capacity, Nuvoco Vistas Corp. Ltd. is the fifth largest cement group in India and amongst the leading cement players in East India.</p>

          <p>The Company's consolidated cement sales volume stood at 4.2 MMT in Q2 FY25. Consolidated revenue from operations stood at Rs. 2,269 crores during the same period. Consolidated EBITDA for the quarter stood at Rs. 229 crores.</p>

          <p>The macro environment remains challenging, characterized by sluggish demand and pricing pressure. While the Union Budget for FY25 has earmarked approximately Rs. 11.11 lakh crores for infrastructure investments, the pace of on-ground execution is yet to see significant pick-up. Additionally, intense and prolonged monsoon conditions during Q2 FY25 have impacted construction activities across various sectors. Despite these challenges, the Company continued to remain focused on operational excellence. The Company has achieved the lowest blended fuel cost in the last 12 quarters, at Rs. 1.54 per Mcal. Moreover, the successful commissioning of the new clinker wagon loading system at Sonadih Cement Plant has enhanced clinker dispatch capacity via rail, reflecting the company’s commitment to improving logistical efficiency.</p>

          <p>Premiumisation remains a key focus area for the Company, with premium products share in trade segment reaching a record high of 43% in Q2 FY25. Amongst the offerings, "Concreto Uno," a premium cement variant, is catering to the growing demand for high-quality construction materials in the East and is witnessing significant traction. In the RMX business, innovative products launched in Q1 FY25, such as “Ecodure Thermal Insulated Concrete” to lower indoor temperatures and “Concreto Uno Concrete,” India’s first hydrophobic concrete, are seeing volume traction. The MBM business introduced “Zero M Roof Shield”, a single-component waterproof coating that reduces surface temperature by up to 10 degrees celsius, ensuring cooler living spaces.</p>

          <p>The Company’s commitment to sustainability is demonstrated by its lowest carbon emissions in the industry, with 457 kg CO2 per ton1 of cementitious materials.</p>

          <p>Commenting on the company’s performance, <b>Mr. Jayakumar Krishnaswamy, Managing Director, Nuvoco Vistas Corp. Ltd.,</b> stated, “Industry has been facing significant headwinds in recent times, including weak demand coupled with pricing pressure. The timing and pace of demand recovery rests upon on-ground execution of infrastructure and housing projects including those under PMAY and Purvodaya schemes. Moreover, sustainability of price improvements is contingent upon sustained demand growth. Nuvoco is navigating these volatilities with resilience by prioritizing premiumization, geo-optimization, brand strength, and operational excellence.”</p>
         
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building materials company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest cement group in terms of capacity (25 MMTPA), with INR 10,733 crore in total revenue from operations in FY24. It is among the leading players in East India. Nuvoco offers a diversified business portfolio in three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes - Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses a pan-India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the firstof-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium (Rourkela), Aquatic Gallery Science City (Ahmedabad), Metro Railway (Delhi, Jaipur, Noida and Mumbai), and among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ brands, comprises construction chemicals, tile adhesives, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p> 
          <p>Know more about the Company on <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default FinancialResultQ2FY25