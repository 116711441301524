import React, {useEffect, useState, useRef} from "react";
import {Container, Row, Col, Card, Breadcrumb,Button,Modal} from 'react-bootstrap';
import Slider from "react-slick";
import ReactPlayer from 'react-player';
import Carousel from 'react-bootstrap/Carousel';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


import Map from './map.js';
import HomeOurBarnd from './home-our-brand';

import banner1 from '../assets/images/newbanner1.jpg';
import banner2 from '../assets/images/newbanner2.png';
import banner3 from '../assets/images/newbanner3.jpg';
import banner4 from '../assets/images/newbanner4.jpg';
import banner5 from '../assets/images/mbm-banner.png'
import banner6 from '../assets/images/sra-banner.jpg'
import homeAbout from '../assets/images/home_about.jpg';
import bCement from '../assets/images/business1.jpg';
import homeAsist from '../assets/images/home_asist.jpg';
import readyMix from '../assets/images/ready_mix.jpg';
import mbuilding from '../assets/images/mbuilding.jpg';
import construction from '../assets/images/construction.png';
import house from '../assets/images/house.png';
import application from '../assets/images/application.png';
import cost from '../assets/images/cost.png';
import st01 from '../assets/images/environment-home.jpg';
import st02 from '../assets/images/governance-home.jpg';
import st03 from '../assets/images/social-home.jpg';
import news01 from '../assets/images/news01.jpg';
import news02 from '../assets/images/news02.jpg';
import news03 from '../assets/images/news03.jpg';
import total from '../assets/images/total-rewards.jpg';
import learning from '../assets/images/learning-developemnt.jpg';
import employee from '../assets/images/employee-engagement.jpg';
import job from '../assets/images/growth-accelerator.jpg';
import people from '../assets/images/people.jpg';
import rightIcon from '../assets/images/right_icon.png';
import landmarkBanner01 from '../assets/images/landmark01.jpg';
import landmarkBanner02 from '../assets/images/landmark02.jpg';
import blogImg from '../assets/images/blog.jpg';

import about01 from '../assets/images/about01.png';
import about02 from '../assets/images/about02.png';
import about03 from '../assets/images/about03.png';
import about04 from '../assets/images/about04.png';
import ourbs01 from '../assets/images/ourbs01.png';
import ourbs02 from '../assets/images/ourbs02.png';
import brand02 from '../assets/images/brand02.png';
import brand03 from '../assets/images/brand03.png';
import brand04 from '../assets/images/brand04.png';
import brand05 from '../assets/images/brand05.png';
import brand06 from '../assets/images/brand06.png';
import landmarkcenter01 from '../assets/images/landmarkcenter01.jpg';
import landmarkcenter02 from '../assets/images/landmarkcenter02.jpg';
import landmarkcenter03 from '../assets/images/landmarkcenter03.jpg';
import landmarkcenter04 from '../assets/images/landmarkcenter04.jpg';
import landmarkcenter05 from '../assets/images/landmarkcenter05.jpg';
import landmarkcenter06 from '../assets/images/landmarkcenter06.jpg';
import landmarkcenter07 from '../assets/images/landmarkcenter07.jpg';
import landmarkcenter08 from '../assets/images/landmarkcenter08.jpg';
import landmarkcenter09 from '../assets/images/landmarkcenter09.jpg';
import landmarkcenter10 from '../assets/images/landmarkcenter10.jpg';
import landmark01 from '../assets/images/landmark01.png';
import landmark02 from '../assets/images/landmark02.png';
import landmark03 from '../assets/images/landmark03.png';
import landmark04 from '../assets/images/landmark04.png';
import landmark05 from '../assets/images/landmark05.png';
import landmark06 from '../assets/images/landmark06.png';
import landmark07 from '../assets/images/landmark07.png';
import landmark08 from '../assets/images/landmark08.png';
import assit01 from '../assets/images/assit01.png';
import assit02 from '../assets/images/assit02.png';
import assit03 from '../assets/images/assit03.png';
import assit04 from '../assets/images/assit04.png';
import footprint02 from '../assets/images/footprint02.png';
import footprint09 from '../assets/images/footprint09.png';
import sus01 from '../assets/images/sus01.png';
import sus03 from '../assets/images/sus03.png';
import sus04 from '../assets/images/sus04.png';
import sus05 from '../assets/images/sus05.png';
import sus06 from '../assets/images/sus06.png';
import sus07 from '../assets/images/sus07.png';
import sus08 from '../assets/images/sus08.png';
import sus09 from '../assets/images/sus09.png';
import sus10 from '../assets/images/sus10.png';
import sus11 from '../assets/images/sus11.png';
import blog01 from '../assets/images/blog01.png';
import blog02 from '../assets/images/blog02.png';
import blog03 from '../assets/images/blog03.png';
import blog04 from '../assets/images/blog04.png';
import blog05 from '../assets/images/blog05.png';
import life01 from '../assets/images/life01.png';
import life02 from '../assets/images/life02.png';
import life03 from '../assets/images/life03.png';
import life04 from '../assets/images/life04.png';
import life05 from '../assets/images/life05.png';
import life06 from '../assets/images/life06.png';
import life07 from '../assets/images/life07.png';
import life08 from '../assets/images/life08.png';
import news04 from '../assets/images/news04.png';
import news05 from '../assets/images/news05.png';
import cementHome from '../assets/images/cementHome.jpg';
import VideoFile from '../assets/images/ballet.mp4';

import { blogApi, mediaRelationApi } from "../redux";
import { connect } from "react-redux";
import BlogCard from "../common/blogCard.jsx";
import { imgUrl } from "../redux/config.jsx";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Helmet } from "react-helmet";

  const settings = {
    autoplay:true,
    autoplaySpeed: 2000,
    dots: false,
    arrow:true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            },
        },{
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
  };
  const settings1 = {
    autoplay:true,
    autoplaySpeed: 2000,
    dots: false,
    arrow:true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
  };
const slideLandmark = {
    autoplay:true,
    autoplaySpeed: 2000,
    dots: false,
    arrow:true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },{
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            },
        }, {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        }
    ],
  };

  

const  Home = ({mediaRelationApi, media, blogApi, blog}) => {   

    useEffect(() => {
        blogApi();
        mediaRelationApi(1, '', '');
	}, []);

    const [isShown, setIsShown] = useState(false);

    const handleClick = () => {
        setIsShown(current => !current);
      };

    if (Object.keys(blog).length) {        
        const blogData = {...blog.data};
        var mediaList = blogData.Blogs.slice(0,9);
    }

    if (Object.keys(media).length) {
        const mediaData = {...media.data};
        const data = JSON.parse(mediaData.media);
        var newList = data.slice(0,9);
    }

    const dateFormat = (date) =>{
        let data = new Date(date);
        data = data.toGMTString();
        data = data.substring(4, 16);
        return data;
    }
 

    const [projectone, setProjectone] = useState(false);
    const closeProjectone = () => setProjectone(false);
    
    const [disclaimer, setDisclaimer] = useState(false);
    const [forDisclaimer, setForDisclaimer] = useState(true);
    const closeDisclaimer = () => { 
        setDisclaimer(false);
        setForDisclaimer(false);
    };


    const [projecttwo, setProjecttwo] = useState(false);
    const closeProjecttwo = () => setProjecttwo(false);

    const [projectthree, setProjectthree] = useState(false);
    const closeProjectthree = () => setProjectthree(false);

    const [projectfour, setProjectfour] = useState(false);
    const closeProjectfour = () => setProjectfour(false);

    const [projectfive, setProjectfive] = useState(false);
    const closeProjectfive = () => setProjectfive(false);

    const [projectsix, setProjectsix] = useState(false);
    const closeProjectsix = () => setProjectsix(false);

    const [projectseven, setProjectseven] = useState(false);
    const closeProjectseven = () => setProjectseven(false);

    const [projecteight, setProjecteight] = useState(false);
    const closeProjecteight = () => setProjecteight(false);

    const [projectnine, setProjectnine] = useState(false);
    const closeProjectnine = () => setProjectnine(false);

    const [projectten, setProjectten] = useState(false);
    const closeProjectten = () => setProjectten(false);


    const [lifenov1, setlifenov1] = useState(false);
    const closelifenov1 = () => setlifenov1(false);

    const [lifenov2, setlifenov2] = useState(false);
    const closelifenov2 = () => setlifenov2(false);

    const [lifenov3, setlifenov3] = useState(false);
    const closelifenov3 = () => setlifenov3(false);

    const [lifenov4, setlifenov4] = useState(false);
    const closelifenov4 = () => setlifenov4(false);
    
    useEffect(() => {
      if(forDisclaimer){
        setTimeout(() => {
            setDisclaimer(false)
            setForDisclaimer(false)
          }, 3000);
      }
    }, []);

    const showComponent=((varname) =>{    
        switch (varname) {
               
            case "projectone":
                setProjectone(true);
                break;
                case "projecttwo":
                setProjecttwo(true);
                break;
                case "projectthree":
                setProjectthree(true);
                break;
                case "projectfour":
                setProjectfour(true);
                break;
                case "projectfive":
                setProjectfive(true);
                break;
                case "projectsix":
                setProjectsix(true);
                break;
                case "projectseven":
                setProjectseven(true);
                break;
                case "projecteight":
                setProjecteight(true);
                break;
                case "projectnine":
                setProjectnine(true);
                break;
                case "projectten":
                setProjectten(true);
                break;

                case "lifenov1":
                    setlifenov1(true);
                    break;
                    case "lifenov2":
                setlifenov2(true);
                break;
                case "lifenov3":
                setlifenov3(true);
                break;
                case "lifenov4":
                setlifenov4(true);
                break;

                
          default: return;
        }
      });

    const slider = useRef();
    const nslider = useRef();
    const slideNext = () =>{
        slider.current.slickNext();
        nslider.current.slickNext();
    }

    const slidePrev = () =>{
        slider.current.slickPrev();
        nslider.current.slickPrev();
    }

    return(
    <div className="home">
    <Helmet>
    <title>Best Cement Company | Cement Suppliers Near Me - Nuvoco Vistas</title>
    <meta name="keywords" description="" />
    <link rel="canonical" href="https://nuvoco.com/"/>
    <meta name="description" content="Nuvoco Vistas is one of the leading cement suppliers in India and rated as one of the best cement dealers and manufacturers. It has cement plants across major parts of India." />
    </Helmet>

{/* <ReactPlayer url={VideoFile} playing="true" /> */}


<div>
<div className="banner text-center wow fadeIn">
                <div>
                    <Carousel>
                    {/* <Carousel.Item>
                            <img className="imgbannerdesktop" src={banner10} alt="" />
                            {<img className="imgbannermobile" src={banner10mobile} alt="" /> }
                        </Carousel.Item> */}
                        <Carousel.Item>
                            <img className="imgbannerdesktop" src={banner6} alt="" />
                            {<img className="imgbannermobile" src={banner6} alt="" /> }
                        </Carousel.Item> 
                        <Carousel.Item>
                            <img className="imgbannerdesktop" src={banner1} alt="" />
                            {<img className="imgbannermobile" src={banner1} alt="" /> }
                        </Carousel.Item> 
                        <Carousel.Item>
                            <img className="imgbannerdesktop" src={banner2} alt="" />
                            {<img className="imgbannermobile" src={banner2} alt="" /> }
                        </Carousel.Item> 
                        <Carousel.Item>
                            <img className="imgbannerdesktop" src={banner3} alt="" />
                            {<img className="imgbannermobile" src={banner3} alt="" /> }
                        </Carousel.Item> 
                        <Carousel.Item>
                            <img className="imgbannerdesktop" src={banner4} alt="" />
                            {<img className="imgbannermobile" src={banner4} alt="" /> }
                        </Carousel.Item> 
                        <Carousel.Item>
                            <img className="imgbannerdesktop" src={banner5} alt="" />
                            {<img className="imgbannermobile" src={banner5} alt="" /> }
                        </Carousel.Item> 
                    </Carousel>    
                </div>
        
                
            </div>

        <section className="about_us">
            <img className="a_icon about_01 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.5s" src={about01} alt=""></img>
            <img className="a_icon about_02 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s" src={about02} alt=""></img>
            <img className="a_icon about_03 wow fadeInUp"  data-wow-duration="2s" data-wow-delay="0.7s" src={about03} alt=""></img>
            <img className="a_icon about_04 wow fadeInUp"  data-wow-duration="2s" data-wow-delay="0.8s" src={about04} alt=""></img>
            <Container>
                <Row className="align-center">
                    <Col sm={5}>
                        <div className="about_img">
                            <div className="wow fadeInLeft" data-wow-delay="0.6s">
                                <img src={homeAbout} alt='' />
                            </div>
                            <div className="inner_box"></div>
                        </div>                       
                    </Col>
                    <Col>
                        <div className="wow fadeInDown abouttext-section" data-wow-delay="0.6s">
                            
                            <h1><Link to='/about-us'>Best Cement Manufacturers</Link></h1>
                            <p className="title">Nuvoco Vistas Corporation Limited (Nuvoco) part of India’s leading business conglomerate – Nirma Group is the fifth-largest cement group in India and the leading 
                            cement player in East India, in terms of capacity. The Company has a vision to
                            Build a Safer, Smarter and Sustainable World and aspires to be a Leading 
                            Building Materials Company Delivering Superior Performance.</p>
                            <Button variant="success" className="read_more" as={Link} to="/about-us">Read More <img src={rightIcon} alt=''/> </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="our_businesses text-center card_round card_hover">
            <img className="a_icon ourbs01 wow fadeInUp" data-wow-delay="0.5s" src={ourbs01} alt=""></img>
            <img className="a_icon ourbs02 wow fadeInUp" data-wow-delay="0.7s" src={ourbs02} alt=""></img>
            <Container>
                <div className="wow fadeInDown" data-wow-delay="0.5s">
                    <h1>Our Businesses</h1>
                    <p className="title">Our diversified business portfolio includes:</p>
                </div>                
                <Row>
                    <Col>
                        <Card className="wow fadeInUp" data-wow-delay="0.5s">
                        <Link to='/top-cement-company'><Card.Img variant="top" src={cementHome} />
                            <Card.Body>
                                <Card.Title className="g_invert">Cement</Card.Title>
                            </Card.Body>
                            </Link>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="wow fadeInUp cardHome" data-wow-delay="0.7s">
                        <Link to="/ready-mix-concrete"><Card.Img variant="top" src={readyMix} />
                            <Card.Body>
                                <Card.Title className="g_invert">Ready-Mix Concrete</Card.Title>
                            </Card.Body>
                            </Link>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="wow fadeInUp" data-wow-delay="0.9s">
                        <Link to="/modern-building-materials"><Card.Img variant="top" src={mbuilding} />
                            <Card.Body>
                                <Card.Title className="g_invert">Modern Building Materials</Card.Title>
                            </Card.Body>
                            </Link>
                        </Card>
                    </Col>
                    
                </Row>
            </Container>
        </section>

        <HomeOurBarnd />
        
        <section className="home_asist card_round our_brands" id='home_assit'>
            <img className="a_icon brand02 wow fadeInUp" data-wow-delay="0.5s" src={brand02} alt='' />
            <img className="a_icon brand03 wow fadeInUp" data-wow-delay="0.6s" src={brand03} alt='' />
            <img className="a_icon brand04 wow fadeInUp" data-wow-delay="0.7s" src={brand04} alt='' />
            <img className="a_icon brand05 wow fadeInUp" data-wow-delay="0.8s" src={brand05} alt='' />
            <img className="a_icon brand06 wow fadeInUp" data-wow-delay="0.9s" src={brand06} alt='' />

            <img className="a_icon assit01 wow fadeInUp" data-wow-delay="0.10s" src={assit01} alt='' />
            <img className="a_icon assit02 wow fadeInUp" data-wow-delay="0.11s" src={assit02} alt='' />
            <img className="a_icon assit03 wow fadeInUp" data-wow-delay="0.12s" src={assit03} alt='' />
            <img className="a_icon assit04 wow fadeInUp" data-wow-delay="0.13s" src={assit04} alt='' />
            <Container>
                <Row>
                    <Col md={12} lg={5} className="wow fadeInDown" data-wow-delay="0.5s">
                    <h1>Nuvo Nirmaan</h1>
                    <p className="title">Nuvonirmaan.com is a one-stop destination for all construction and building-related information. So stop searching for cement suppliers near me with us. Being a leading cement company in India we aim to provide knowledge, share expertise, make Individual Home Builders (IHBs) aware of the latest and innovative products and offering them with financial guidance in a systematic and authentic manner through a series of informational videos and textual explanations.</p>
                    </Col>

                    <Col md={12} lg={7} className="wow fadeInRight" data-wow-delay="0.5s">
                        <img className="assit_banner" src={homeAsist} alt='' />
                    </Col>
                </Row>

                <div className="brand_carousel text-center">
                    <Row>
                        <Col className="wow fadeInUp col-6" md={3} data-wow-delay="0.5s">
                            <a href="https://nuvonirmaan.com/construction-stages/sub-structure/" rel="noreferrer" target="_blank" title="Construction Stages">
                            <Card>
                                <Card.Img variant="top" src={construction} />
                                <p className="g_invert">&nbsp;</p>
                            </Card>
                            <h4>Construction Stages</h4>
                            </a>
                        </Col>
                        <Col className="wow fadeInUp col-6" md={3} data-wow-delay="0.6s">
                        <a href="https://nuvonirmaan.com/house-plans/"  rel="noreferrer" target="_blank" title="House Plans">
                            <Card>
                                <Card.Img variant="top" src={house} />
                                <p className="g_invert">&nbsp;</p>
                            </Card>
                            <h4>House Plans</h4>
                            </a>
                        </Col>
                        <Col className="wow fadeInUp col-6" md={3} data-wow-delay="0.7s">
                        <a href="https://nuvonirmaan.com/#ApplicationGuide" rel="noreferrer" target="_blank" title="Application Guide">
                            <Card>
                                <Card.Img variant="top" src={application} />
                                <p className="g_invert">&nbsp;</p>
                            </Card>
                            <h4>Application Guide</h4>
                            </a>
                        </Col>
                        <Col className="wow fadeInUp col-6" md={3} data-wow-delay="0.8s">
                        <a href="https://nuvonirmaan.com/calculators/" rel="noreferrer" target="_blank" title="Cost Calculator">
                            <Card>
                                <Card.Img variant="top" className="cost" src={cost} />
                                <p className="g_invert">&nbsp;</p>
                            </Card>
                            <h4>Cost Calculator</h4>
                            </a>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>

        <section className="landmark card_round">
            <img className="a_icon landmark01 wow fadeInUp" data-wow-delay="0.5s" src={landmark01} alt='' />
            <img className="a_icon landmark02 wow fadeInUp" data-wow-delay="0.6s" src={landmark02} alt='' />
            <img className="a_icon landmark03 wow fadeInUp" data-wow-delay="0.7s" src={landmark03} alt='' />
            <img className="a_icon landmark04 wow fadeInUp" data-wow-delay="0.8s" src={landmark04} alt='' />
            <img className="a_icon landmark05 wow fadeInUp" data-wow-delay="0.9s" src={landmark05} alt='' />
            <img className="a_icon landmark06 wow fadeInUp" data-wow-delay="0.10s" src={landmark06} alt='' />
            <img className="a_icon landmark07 wow fadeInUp" data-wow-delay="0.11s" src={landmark07} alt='' />
            <img className="a_icon landmark08 wow fadeInUp" data-wow-delay="0.12s" src={landmark08} alt='' />
          
            <Container className="full">
                <div className="fullSilder">                
                    <div className="slide_cta">
                    <Slider ref={(c) => (slider.current = c)} {...slideLandmark}>
                            <div className="wow fadeInLeft" data-wow-delay="0.5s">
                            <Card onClick={() => showComponent('projectone')}>
                                    <Card.Img variant="top" src={landmarkcenter01} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.7s">
                                        <Card.Title>Khodhaldham Temple</Card.Title>
                                        <p>Rajkot, Ahemdabad</p>
                                    </Card.Body>
                                </Card>
                            </div>                 
                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projecttwo')}>
                                    <Card.Img variant="top" src={landmarkcenter02} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Sumadhura Shikharam</Card.Title>
                                        <p>Bangalore</p>
                                    </Card.Body>
                                </Card>
                            </div>              
                            <div className="wow fadeInLeft" data-wow-delay="0.5s">
                            <Card onClick={() => showComponent('projectthree')}>
                                    <Card.Img variant="top" src={landmarkcenter03} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.7s">
                                        <Card.Title>CEEBROS</Card.Title>
                                        <p>Chennai</p>
                                    </Card.Body>
                                </Card>
                            </div>                 
                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projectfour')}>
                                    <Card.Img variant="top" src={landmarkcenter04} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Metro</Card.Title>
                                        <p>Delhi, Noida, Jaipur and Mumbai</p>
                                    </Card.Body>
                                </Card>
                            </div>       

                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projectfive')}>
                                    <Card.Img variant="top" src={landmarkcenter05} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Sharda University</Card.Title>
                                        <p>Delhi</p>
                                    </Card.Body>
                                </Card>
                            </div>   

                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projectsix')}>
                                    <Card.Img variant="top" src={landmarkcenter06} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Suven Life Sciences</Card.Title>
                                        <p>Hyderabad</p>
                                    </Card.Body>
                                </Card>
                            </div>   

                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projectseven')}>
                                    <Card.Img variant="top" src={landmarkcenter07} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Dravyavati River Rejuvenation</Card.Title>
                                        <p>Jaipur</p>
                                    </Card.Body>
                                </Card>
                            </div>   

                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projecteight')}>
                                    <Card.Img variant="top" src={landmarkcenter08} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Nazrul Tirtha</Card.Title>
                                        <p>Kolkata</p>
                                    </Card.Body>
                                </Card>
                            </div>   

                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projectnine')}>
                                    <Card.Img variant="top" src={landmarkcenter09} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Lodha World One</Card.Title>
                                        <p>Mumbai</p>
                                    </Card.Body>
                                </Card>
                            </div>   
                            
                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projectten')}>
                                    <Card.Img variant="top" src={landmarkcenter10} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Amritsar entry gate</Card.Title>
                                        <p>Punjab</p>
                                    </Card.Body>
                                </Card>
                            </div>   
                            
                        </Slider>
                    </div>
                    <div className="right_cta wow fadeInDown" data-wow-delay="0.5s">
                        <h1>Landmark Projects</h1>
                        <h4>Proud Contributors to Iconic Structures​</h4>
                        <p className="title mb-6">We are preferred cement suppliers for contractors, engineers and architects, and are proud contributors to landmark projects. </p>
                        <div className="d-flex">
                            <button type="button" data-role="none" onClick={slidePrev} class="slick-arrow slick-prev"> Previous</button>
                            <button type="button" data-role="none" onClick={slideNext} class="slick-arrow slick-next"> Next</button>
                            <Button variant="success" as={Link} to={{
                                    pathname: "/ready-mix-concrete",
                                    hash: "#landmark",
                                }}>View All</Button>
                        </div>
                    </div>               
                </div>
            </Container>
        </section>

        <Map />
      
        <section className="sustainability card_round text-center card_hover">
            <img className="a_icon sus01 wow fadeInUp" data-wow-delay="0.5s" src={footprint09} alt=''/>
            <img className="a_icon sus02 wow fadeInUp" data-wow-delay="0.6s" src={sus01} alt=''/>
            <img className="a_icon sus03 wow fadeInUp" data-wow-delay="0.7s" src={sus03} alt=''/>            
            <img className="a_icon sus08 wow fadeInUp" data-wow-delay="0.8s" src={sus08} alt=''/>            
            <img className="a_icon sus09 wow fadeInUp" data-wow-delay="0.9s" src={sus09} alt=''/>            
            <img className="a_icon sus10 wow fadeInUp"data-wow-delay="0.10s"  src={sus10} alt=''/>            
            <img className="a_icon sus11 wow fadeInUp" data-wow-delay="0.11s" src={sus11} alt=''/>            
            <div className="bg_img">
                <Container className="wow fadeInDown" data-wow-delay="0.5s">
                    <a href="/sustainability"><h1>Sustainability</h1></a>
                    <p className="title">We are committed to take positive steps towards the betterment of the environment, 
                    preserve our natural resources and reduce carbon emissions. Every effort we make is a step towards a
                    healthier and greener planet.​</p>
                    <Button variant="success" className="read_more" as={Link} to="/sustainability">Read More</Button>                                  
                </Container>
            </div>
            
            <div className="st_cta">
                <Container>
                    <div className="sus_in">
                        <Row>
                            <Col className="wow fadeInUp" data-wow-delay="0.5s">
                            <Link to='/environment'>  <Card>
                                    <Card.Img variant="top" src={st01} />
                                    <Card.Body>
                                        <Card.Title className="g_invert">Environment</Card.Title>
                                    </Card.Body>
                                </Card></Link>
                            </Col>

                            <Col className="wow fadeInUp" data-wow-delay="0.7s">
                            <Link to='/social'>  <Card>
                                    <Card.Img variant="top" src={st02} />
                                    <Card.Body>
                                        <Card.Title className="g_invert">Social </Card.Title>
                                    </Card.Body>
                                </Card>
                                </Link>
                            </Col>

                            <Col className="wow fadeInUp" data-wow-delay="0.8s">
                            <Link to='/governance'>  <Card>
                                    <Card.Img variant="top" src={st03} />
                                    <Card.Body>
                                        <Card.Title className="g_invert">Governance</Card.Title>
                                    </Card.Body>
                                </Card></Link>
                            </Col>
                            
                        </Row>
                    </div>
                </Container>
            </div>
        </section>

        <section className="news card_round">
            <img className="a_icon sus04 wow fadeInUp" data-wow-delay="0.5s" src={sus04} alt=''/>
            <img className="a_icon sus05 wow fadeInUp" data-wow-delay="0.6s" src={sus05} alt=''/>
            <img className="a_icon sus06 wow fadeInUp" data-wow-delay="0.7s" src={sus06} alt=''/>
            <img className="a_icon sus07 wow fadeInUp" data-wow-delay="0.8s" src={sus07} alt=''/>
            <img className="a_icon news04 wow fadeInUp" data-wow-delay="0.9s" src={news04} alt=''/>
            <img className="a_icon news05 wow fadeInUp" data-wow-delay="0.10s" src={news05} alt=''/>
            <img className="a_icon news06 wow fadeInUp" data-wow-delay="0.11s" src={footprint02} alt=''/>
            <Container className="full">
            <div className="fullSilder">                
                <div className="slide_cta">                    
                        {(Object.keys(media).length && (     
                            <Slider ref={(c) => (nslider.current = c)} {...settings1}>   
                            {newList.map((slide, index) =>{
                            return (
                                    <div key={index} className="wow fadeInLeft blog" data-wow-delay="0.5s">
                                        <BlogCard item={slide} id={1} uri ={imgUrl} />
                                    </div>
                                    );
                                })}  
                            </Slider>
                        )) || ''} 
                   
                </div>
                <div className="right_cta wow fadeInDown nuvocoNewsSection" data-wow-delay="0.5s">
                    
                    <h1><Link to='/media'>Nuvoco In News</Link></h1>

                    <div className="d-flex">
                        <button type="button" data-role="none" onClick={slidePrev} class="slick-arrow slick-prev"> Previous</button>
                        <button type="button" data-role="none" onClick={slideNext} class="slick-arrow slick-next"> Next</button>
                        <Button variant="success" as={Link} to="/media/Press-Releases" className="nuvocoNewsinner">View All</Button>
                    </div>            
                    
                </div>               
            </div>
            </Container>
        </section>

        <section className="blog text-center card_round wow fadeInUp" data-wow-delay="0.5s">
            <img className="a_icon blog01 wow fadeInUp" data-wow-delay="0.5s" src={blog01} alt=''/>
            <img className="a_icon blog02 wow fadeInUp" data-wow-delay="0.6s" src={blog02} alt=''/>
            <img className="a_icon blog03 wow fadeInUp" data-wow-delay="0.7s" src={blog03} alt=''/>
            <img className="a_icon blog04 wow fadeInUp" data-wow-delay="0.8s" src={blog04} alt=''/>
            <img className="a_icon blog05 wow fadeInUp" data-wow-delay="0.9s" src={blog05} alt=''/>
            <img className="a_icon blog06 wow fadeInUp" data-wow-delay="0.10s" src={landmark08} alt=''/>
            <Container>
               
                <h1 className="BlogHeadcut"><Link to='/media'>Blogs</Link></h1>
                <p className="title">We are leading cement manufacturers with wide-range of solutions crafted by experts keeping in mind a home-builder’s needs</p>
                {(Object.keys(blog).length && (
                    <Slider {...settings}>
                        {mediaList.map((slide, index) =>{
                            return (
                                <div key={index} className="wow fadeInUp" data-wow-delay="0.5s">
                                    <BlogCard item={slide} id={4} uri ={imgUrl} />
                                </div>
                            );
                        })}                
                    </Slider>     
                 )) || ''}        
                <div className="text-right">
                    <Button variant="success" className="read_more" as={Link} to="/media/blog">View All Blogs</Button>
                </div>
            </Container>
        </section>

        <section className="life_novoco card_round">
            <img className="a_icon life01 wow fadeInUp" data-wow-delay="0.5s" src={life01} alt=''/>
            <img className="a_icon life02 wow fadeInUp" data-wow-delay="0.6s" src={life02} alt=''/>
            <img className="a_icon life03 wow fadeInUp" data-wow-delay="0.7s" src={life03} alt=''/>
            <img className="a_icon life04 wow fadeInUp" data-wow-delay="0.8s" src={life04} alt=''/>
            <img className="a_icon life05 wow fadeInUp" data-wow-delay="0.9s" src={life05} alt=''/>
            <img className="a_icon life06 wow fadeInUp" data-wow-delay="0.10s" src={life06} alt=''/>
            <img className="a_icon life07 wow fadeInUp" data-wow-delay="0.11s" src={life07} alt=''/>
            <img className="a_icon life08 wow fadeInUp" data-wow-delay="0.12s" src={life08} alt=''/>
            <Container>
                <Row>
                    <Col md={12} lg={5} className="wow fadeInDown" data-wow-delay="0.5s">
                        
                        <h1><Link to='/life-at-nuvoco'>Life At <br/> Nuvoco</Link></h1>

                        <p className="title">
                            With over 3500 employees across multiple locations, we strive to make the company one of the 
                            best places to work. At Nuvoco, we respect diversity across all ages, professions, 
                            and genders and believe in being an equal opportunity employer.  ​
                        </p>
                    </Col>
                    <Col lg={7}>
                        <Row>
                            <Col md={6}  className="wow fadeInUp mb-4" data-wow-delay="0.5s">
                                <Card onClick={() => showComponent('lifenov1')}>
                                    <Card.Img variant="top" src={total} />
                                    <Card.Body>
                                        <Card.Title className="g_invert">Total Rewards</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md={6} className="wow fadeInUp mb-4" data-wow-delay="0.6s">
                                <Card onClick={() => showComponent('lifenov2')}>
                                    <Card.Img variant="top" src={learning} />
                                    <Card.Body>
                                        <Card.Title className="g_invert">Learning and Development</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>     
                            <Col md={6} className="wow fadeInUp mb-4" data-wow-delay="0.7s">
                                <Card onClick={() => showComponent('lifenov3')}>
                                    <Card.Img variant="top" src={employee} />
                                    <Card.Body>
                                        <Card.Title className="g_invert">Employee Engagement</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6} className="wow fadeInUp mb-4" data-wow-delay="0.8s">
                                <Card onClick={() => showComponent('lifenov4')}>
                                    <Card.Img variant="top" src={job} />
                                    <Card.Body>
                                        <Card.Title className="g_invert">Growth Accelerator</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>                       
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Disclaimer Modal */}
    {/* <Modal  size="lg" centered="true"
        show={disclaimer} onHide={closeDisclaimer} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
            <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <article className="p-4">
                            <h4>Khodhaldham Temple</h4>
                            <p>One of the largest comprehensive Hindu Temples in India that is 299 feet tall. It is renowned for setting Guinness World Record in the category of the highest number of people (3.5 lakhs) singing the national anthem at a single venue. Nuvoco’s Artiste installed on the steps that cover a 39,600 sq. ft. area and has footfall as high as three lakhs.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal> */}
    <Modal size="lg" centered="true"
                show={disclaimer} onHide={closeDisclaimer} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Disclaimer</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <article className="p-4">
                                    <p>Dear Nuvoco Investor,</p>
                                    <p>We request you to register/update your e-mail Id, PAN and bank account details with your respective Depository Participants.</p>
                                    <p>For any query or assistance, please reach out to us at <a href="mailto:investor.relations@nuvoco.com">investor.relations@nuvoco.com</a>.</p>
                                    <p><strong>Investor Relations</strong></p>
                                </article>
                            </div>


                        </Col>
                    </Row>
                    
                </Modal.Body>
            </Modal>


     <Modal  size="lg" centered="true"
        show={projectone} onHide={closeProjectone} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter01} alt='' />
                        <article className="p-4">
                            <h4>Khodhaldham Temple</h4>
                            <p>One of the largest comprehensive Hindu Temples in India that is 299 feet tall. It is renowned for setting Guinness World Record in the category of the highest number of people (3.5 lakhs) singing the national anthem at a single venue. Nuvoco’s Artiste installed on the steps that cover a 39,600 sq. ft. area and has footfall as high as three lakhs.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projecttwo} onHide={closeProjecttwo} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter02} alt='' />
                        <article className="p-4">
                            <h4>Sumadhura Shikharam</h4>
                            <p>Sumadhura Infracon Pvt. Ltd. is a name that resonates among the prominent residential and commercial developers in Bengaluru. A posh project in pristine surroundings that boosts tranquillity; these high-rise apartments are studded with world-class amenities. Artiste’s subtle design provides vibrant colour and realistic texture.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectthree} onHide={closeProjectthree} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter03} alt='' />
                        <article className="p-4">
                            <h4>CEEBROS</h4>
                            <p>With an uncompromising passion for the highest quality and detailing, exceptional design and craftsmanship, CEEBROS Property Development has been leading the luxury residential market in Chennai. They were the first ones to build a five-star Ecotel Hotel ‘The Rain Tree’ in South India. Nuvoco’s XLite was used to fill the complex driveway of their premium residential complex ONE 74.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectfour} onHide={closeProjectfour} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter04} alt='' />
                        <article className="p-4">
                            <h4>Metro</h4>
                            <p>Nuvoco executed three underground metro stations with one launching shaft and one crossover for the Delhi Metro project. Delivered 1,40,000 cum in 20 months and erected two plants in the record time of 50 days to support the uninterrupted supply of concrete.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectfive} onHide={closeProjectfive} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter05} alt='' />
                        <article className="p-4">
                            <h4>Sharda University</h4>
                            <p>A leading educational institution located in Greater Noida, Delhi NCR that spread across a sprawling campus of 63 acres and housing students from more than 74 countries other than India. Nuvoco’s Artiste offers beauty and durability to the construction.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectsix} onHide={closeProjectsix} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter06} alt='' />
                        <article className="p-4">
                            <h4>Suven Life Sciences</h4>
                            <p>A biopharmaceutical company focused on discovering, developing and commercializing novel pharmaceutical products. Nuvoco’s Concreto Agile helped in the expansion process [building of five bulk chemical storage tanks] of their existing plant.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectseven} onHide={closeProjectseven} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter07} alt='' />
                        <article className="p-4">
                            <h4>Dravyavati River Rejuvenation</h4>
                            <p>A project by TATA Projects Limited for restoring a 47.5 km (rain-fed) riverfront of untreated sewage nallah to a perennial river - fed by treated clean water and beatifying it. Nuvoco’s range of ready-mix concrete is helping in shaping this project and achieve uncompromised results.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projecteight} onHide={closeProjecteight} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter08} alt='' />
                        <article className="p-4">
                            <h4>Nazrul Tirtha</h4>
                            <p>A cultural and educational centre dedicated to Kazi Nazrul Islam that has 'Unnata mama shir' [Head held high] carved on its outer wall. Nuvoco’s free-flowing concrete ‘Agile’ was the ideal choice for getting the desired carving.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectnine} onHide={closeProjectnine} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter09} alt='' />
                        <article className="p-4">
                            <h4>Lodha World One</h4>
                            <p>A residential skyscraper of 117 floors each covering 7, 50,000 sq meter area and offering more than 300 apartments. Nuvoco’s Agile was the perfect fit; its free-flowing property eliminates the need for vibration and allows easy placement offering desired look and shape.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectten} onHide={closeProjectten} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter10} alt='' />
                        <article className="p-4">
                            <h4>Amritsar entry gate</h4>
                            <p>An entry gate that is 50 meters tall and has the second largest dome in diameter [4000 sq. ft.] after India's entry in the seven wonders of the world at the entrance of the majestic city of Amritsar. Nuvoco’s Xlite – a low density and thermally efficient concrete helped maintain the structural load of the dome at the top and provide a beautiful structural finish to this landmark.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>

{/* life at nuvoco popup modal */}


<Modal   size="lg" centered="true"
        show={lifenov1} onHide={closelifenov1} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center lifepopupdesign">
                       <article className="p-4">
                            <h4>Total Rewards</h4>
                            <p>Nuvoco's compensation philosophy emphasizes equality, performance-based compensation, and market parity. Endeavouring employees are our identity, and their hard work is rewarded at every step. We value fair and competitive compensation and benefits that attract, retain, and reward our highest performers at all levels. Our policies are customized according to their needs, and we identify our employees as our assets, which provides us with motivation to pursue bigger goals.  
</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal   size="lg" centered="true"
        show={lifenov2} onHide={closelifenov2} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center lifepopupdesign">
                    <article className="p-4">
                    <h4>Learning and Development</h4>
                    <p>We at Nuvoco, strongly believe in preparing our workforce to be future ready by building their individual and functional capabilities by focusing on their skills that will help them consistently deliver in this VUCA (volatile, uncertain, complex, and ambiguous) world. Our unique programs - Inspiring Insights, Building Outstanding Leadership Talent (BOLT) and Young Talent Development Program (YTDP) are custom-designed on the adult learning principles and is cascaded through trainings under the concept of I Learn and Lead.</p>
<p><b>BUILD :</b> Building individual and functional capabilities through self-paced and instructor lead virtual programs.</p>
<p><b>NURTURE :</b> Nurturing young talent to take future leadership roles and create a good succession pipeline </p>
<p><b>GROW :</b> Growing leadership capabilities of identified individuals across levels to lead the organization to be the leading Building Materials Company in India.
</p>
                        
<p><b>Inspiring Insights</b> helps in identifying and nurturing young talent, growing them to become future leaders. Through this program, identified young talent are assessed on their leadership competencies through assessments conducted by reputed international organizations and mentored by internal mentors to groom them for becoming future leaders.                   
</p> 

<p><b>Building Outstanding Leadership Talent (BOLT)</b> is a unique and customised program designed by globally recognized organizations for the Leadership team. As part of this program, identified high potential employees get to acquire knowledge and skills through globally acclaimed open / customized programs.</p>
<p>
<b>Young Talent Development Program (YTDP)</b> is a strong orientation to the young talent that helps in developing leadership competencies with the guidance from external faculty as well as mentors (senior leadership) and through action learning projects. 
</p>
<p>
These programs are a combination of instructor lead virtual programs, classroom trainings, campus programs and self-paced online trainings. With a strong focus on Leadership development & building functional and individual capabilities we are sure to be the Leading Building Material Company Delivering Superior Performance. 
</p>
                            
</article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal   size="lg" centered="true"
        show={lifenov3} onHide={closelifenov3} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center lifepopupdesign">
                       <article className="p-4">
                            <h4>Employee Engagement</h4>
                            <p>As a performance-driven organization, maintaining commitments and setting benchmarks are important to us. We provide several platforms to recognize our employees' achievements. Nuvoco Edge Awards (NEA) are one such platform, where each Nuvocan looks forward to being recognized for their accomplishments. These achievements are the recognition of value-based initiatives (Entrepreneurship, Care, Collaboration and Operational Excellence) undertaken by an individual or by a team. The company also offers various R&R programs and employee engagement activities, which help keep employees engaged with the company's cultures while sharpening their artistic skills.
</p>

                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal   size="lg" centered="true"
        show={lifenov4} onHide={closelifenov4} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center lifepopupdesign">
                       <article className="p-4">
                            <h4>Growth Accelerator</h4>
                            <p>We have been fortunate to have strong human capital. One key reason is tenure and depth of knowledge. Nuvoco offers employees numerous opportunities to move across the organization both functionally and geographically, based on merit, by screening relevant internal talents before launching an external search. This helps us enhance our Talent Development strategy by identifying and grooming internal talent and fostering personal and professional growth among our employees. </p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
</div>


          
    </div>


  );
}

const mapStatetoProps = (state) => {
	return {
        blog: state.mediaR.blog,
        media: state.mediaR.media,
	};
};
const mapDispatchtoProps = (dispatch) => {
	return {
        mediaRelationApi: function (category_id, month_id, year_id) {
			dispatch(mediaRelationApi(category_id, month_id, year_id));
		},
		blogApi: function () {
			dispatch(blogApi());
		},
	};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Home);
