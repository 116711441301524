import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const KeyLimestoneBlocks = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Vistas secures key limestone blocks at competitive rates strengthening its operational efficiency in Rajasthan  | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas secures key limestone blocks at competitive rates strengthening its operational efficiency in Rajasthan" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/key-limestone-blocks-at-competitive-rates"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Final%20Release%20-%20Nimbol%20Limestone%20-%2007Oct24.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Vistas secures key limestone blocks at competitive rates strengthening its operational efficiency in Rajasthan</h2>
         
          <p className='onetxt'><strong>Rajasthan, October 07, 2024:</strong> Nuvoco Vistas Corp. Ltd., India's fifth-largest cement group, has been declared the preferred bidder for three significant limestone blocks (NB-01, NB-02, NB-03) located at Nimbol, Tehsil Jaitaran, District Beawar, Rajasthan, during the recently concluded auctions held by the Government of Rajasthan. These auctions were conducted via the MSTC e-auction portal. The auction saw participation from major cement players and other industries.</p>

          <p>The blocks cover 41.37 hectares, have limestone resources estimated at 28.43 million tons, and are located near our cement manufacturing facility in Nimbol, Rajasthan, which provides a strategic advantage. Furthermore, these blocks were secured at a highly competitive price compared to other cement players. By having access to these deposits, the company can ensure the long-term sustainability of its operations through increased limestone availability.</p>

          <p><strong>Mr. Jayakumar Krishnaswamy, Managing Director, Nuvoco Vistas Corp. Ltd., commented,</strong> "By acquiring these limestone blocks, the Company ensures access to high-quality resources to strengthen its operations. This strategic move will boost our production capabilities, allowing us to more effectively meet the increasing demands of the cement industry. It reinforces our commitment to maintaining our position as a leading building materials company while driving excellence andsustained growth."</p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building materials company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest cement group in terms of capacity (25 MMTPA), with INR 10,733 crore in total revenue from operations in FY24. It is among the leading players in East India. Nuvoco offers a diversified business portfolio in three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes - Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses a pan-India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium (Rourkela), Aquatic Gallery Science City (Ahmedabad), Metro Railway (Delhi, Jaipur, Noida and Mumbai), and among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ brands, comprises construction chemicals, tile adhesives, wall putty and cover blocks. Through the NABLaccredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on to <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default KeyLimestoneBlocks