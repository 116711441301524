import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const UnveilsNuvoSetuApp = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Vistas Unveils Nuvo Setu App: A Digital Leap in Customer-Centric Innovation | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas Unveils Nuvo Setu App: A Digital Leap in Customer-Centric Innovation" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/unveils-nuvo-setu-app"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/PR%20Nuvo%20Setu%20Launch%20-23.11.2024%20(1).pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Vistas Unveils Nuvo Setu App: A Digital Leap in Customer-Centric Innovation</h2>
         
          <p className='onetxt'><strong>Gwalior, Madhya Pradesh, 23 November 2024: Nuvoco Vistas Corp. Ltd.,</strong> India's fifth-largest cement group, has successfully launched its innovative Nuvo Setu App, designed to redefine the customer experience foritsCement and MBM (Modern Building Materials) customers.</p>

          <p>The first pilot launch was held at Gwalior in the presence of our esteemed dealers. The launch event was a resounding success, with customers placing live orders through the app. The Nuvo Setu App is available for download on both iOS and Android platforms, ensuring seamless access for users across all devices.</p>

          <p>The Nuvo Setu App is thoughtfully designed to empower customers by streamlining operations and enhancing convenience. Customers can place orders effortlessly, track their real-time order status, and access detailed product information, including applications & benefits. Additionally, the app features a comprehensive credit management system, enabling users to view outstanding balances and make informed financial decisions.</p>

        <p><strong>Chirag Shah, Head of Marketing, Innovation, and Sales Excellence at Nuvoco Vistas Corp. Ltd.,</strong> commented, <i>“We are excited to introduce the Nuvo Setu App, a one-stop digital platform tailored to our customers’ needs. This app highlights Nuvoco’s commitment to customer-centricity by delivering services with accuracy, speed, and delight, setting new standards in customer-focused solutions. The Nuvo Setu App will soon be rolled out nationwide, enabling customers across India to experience its innovative features and capabilities.”</i></p>
          <p>The Nuvo Setu App is a significant addition to Nuvoco’s Project DEN (Digitally Enabled Nuvoco) agenda, designed to leverage advanced technology to enhance efficiency through automation and standardisation. This app complements Nuvoco’s expanding suite of digital tools, including Nuvo Nirmaan—an app that guides customers throughthe various stages of home building and construction, NuTEP—the digital expense management solution, and the SAP Ariba portal—ensuring fair and competitive contracts for suppliers, further strengthening the company’s digital transformation journey.</p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building materials company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest cement group in terms of capacity (25 MMTPA), with INR 10,733 crore in total revenue from operations in FY24. It is among the leading players in East India. Nuvoco offers a diversified business portfolio in three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes - Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses a pan-India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium (Rourkela), Aquatic Gallery Science City (Ahmedabad), Metro Railway (Delhi, Jaipur, Noida and Mumbai), and among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ brands, comprises construction chemicals, tile adhesives, wall putty and cover blocks. Through the NABLaccredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on to <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default UnveilsNuvoSetuApp