import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const UnveilsUniqueDuraguardMicrofiber = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Unveils unique Duraguard Microfiber consumer Offer | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Unveils unique Duraguard Microfiber consumer Offer" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/unveils-unique-duraguard-microfiber"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Duraguard%20Fiber%20Ki%20Khubi%20-%20Final%20PR.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Unveils unique Duraguard Microfiber consumer Offer</h2>
         
          <p className='onetxt'><strong>Bhubaneshwar, June 12, 2024:</strong> Nuvoco Vistas Corp. Ltd., India's fifth-largest cement group in terms of capacity, launched the Duraguard Microfiber Consumer Offer in Western Odisha.</p>

          <p>This consumer offer is designed to offer lucrative benefits to consumers. A consumer purchasing 100 bags of Duraguard Microfiber will get a complimentary 5 kgs pack of branded atta. This unique campaign will run for over 2 months till the end of July 2024.</p>

          <p>Nuvoco’s Duraguard Microfiber cement is a uniquely formulated product with visible fibers that enhance binding, ensuring longer-lasting construction. This product is produced on a patented technology of Fiber Reinforced Cement Composition and stands out in the market for its superior quality and durability.</p>

          <p>The inauguration ceremony took place at the dealer counter of M/s Sidha Shankar Iron in Bolangir, Odisha. The event was attended by Mr. Ashok Rath, Regional Sales Manager (ASM) of Western Odisha, and saw active participation from customers. This led to an on-the-spot order of 700 Duraguard Microfiber bags through the dealer, M/s Sidha Shankar Iron. Nuvoco’s Sales, Marketing, and BD teams were also present during the launch.</p>

          <p>To boost the Offer's reach and engagement, the unique <b>"Fiber Shakti Challenge"</b> was introduced. Nuvoco’s branded vans will travel through density areas, showcasing the product's features and benefits. A key feature of this campaign is a special tear-resistant leaflet, symbolizing the strength of fiber. In a specially designed engagement activity, the audience will be invited to tear the nontearable leaflet. This interactive approach aims to leave a lasting impression on consumers about the benefits of fiber in construction. This initiative aims to generate excitement and interest in our product across target markets.</p>

          <p><strong>Mr. Sunil Mahajan, Head of Sales and Business Development (East), Nuvoco Vistas Corp. Ltd.,</strong> said “Launching the Duraguard Microfiber Consumer Offer underscores our commitment to empowering individual home buyers (IHB) with superior construction solutions. This initiative not only highlights the durability and strength of our premium products but also enriches the building experience of the customers with valuable offerings. We are excited to bring this innovative product demonstration to Odisha and other key states, reaffirming Nuvoco's leadership in providing high-quality cement solutions tailored for the unique needs of homeowners.”</p>

          <p><strong>Mr. Chirag Shah, Head, Marketing & Innovation, Nuvoco Vistas Corp Ltd.,</strong> said, "The 'Fiber Ki Khubi - Andar Bhi, Bahar Bhi' campaign underscores the unique benefits of our patented technology, on which Duraguard Microfiber cement is manufactured, ensuring stronger and more durable constructions. The campaign is inspired by the idea - just as fibers in our food provide benefits to our body, and fibers in our clothes offer care and comfort, homes can also benefit from cement fibers that offer long-lasting protection. With this thought, the promotion aims to emphasize how fiber enhances performance in the context of 'Roti, Kapda aur Makaan'. We are confident that this offer will drive consumer interest and satisfaction, reinforcing Nuvoco as a preferred choice in the individual home buyers (IHB) segment. Launched in Western Odisha, the offer will also run in our key markets in Chhattisgarh, Rajasthan, and Gujarat."</p>
        
          <p>Nuvoco Vistas is committed to delivering innovative and high-quality products to its consumers. The Duraguard Microfiber Consumer offer is a testament to the Company’s dedication to enhancing consumer satisfaction and promoting superior construction practices</p>

          <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building materials company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest cement group in terms of capacity (25 MMTPA), with INR 10,733 crore in total revenue from operations in FY24. It is among the leading players in East India. Nuvoco offers a diversified business portfolio in three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes - Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses a pan-India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium (Rourkela), Aquatic Gallery Science City (Ahmedabad), Metro Railway (Delhi, Jaipur, Noida and Mumbai), and among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ brands, comprises construction chemicals, tile adhesives, wall putty and cover blocks. Through the NABLaccredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on to <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default UnveilsUniqueDuraguardMicrofiber