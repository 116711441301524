import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const FinancialResultQ1FY25 = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Financial result Q1 FY25 | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="NUVOCO Vistas announces its financial results for Q1 FY25" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/financial-result-q1-fy25"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/financial-results-for-Q1-FY25.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>NUVOCO Vistas announces its financial results for Q1 FY25</h2>
          
          <ul>
            <li>Consolidated revenue from operation stood at Rs. 2,636 crores</li>
            <li>Consolidated EBITDA stood at Rs. 348 crores</li>
          </ul>
          <p className='onetxt'>
            <strong>Mumbai, July 31, 2024: </strong>Nuvoco Vistas Corp. Ltd., a leading building materials Company in India, announced its unaudited financial results for the quarter ended June 30, 2024. With 25 MMTPA of combined installed capacity, Nuvoco Vistas Corp. Ltd. is the fifth largest cement group in India and amongst the leading cement players in East India.</p>

          <p>The Company's consolidated cement sales volume stood at 4.8 MMT in Q1 FY25. Consolidated revenue from operations stood at Rs. 2,636 crores, during the same period. Consolidated EBITDA for the quarter stood at Rs. 348 crores.</p>

          <p>Premium products remain a key focus for the Company, with their share in trade volume reaching a record high of 40% in Q1 FY25. To enhance brand equity, we launched an innovative campaign for Duraguard Microfiber Cement, a next-generation PPC variant designed to create awareness of its unique Microfiber technology, which results in structures with high strength, damp resistance, and minimal cracks. Additionally, the Company introduced 'Concreto UNO', a premium cement variant in West Bengal, catering to the growing demand for high-quality construction materials in the region. In the RMX Business, the Company launched Ecodure Thermal Insulated Concrete, which exhibits lower thermal conductivity than conventional concrete and Concreto Uno – Hydrophobic Concrete, which enhances the overall strength and durability of structures.</p>

          <p>The Company’s commitment to sustainability is highlighted by its lowest carbon emissions in the industry, with 457 kg CO2 per ton1 of cementitious materials.</p>

          <p>Commenting on the performance of the Company, <b>Mr. Jayakumar Krishnaswamy, Managing Director, Nuvoco Vistas Corp. Ltd.,</b> stated, “The Company navigated the quarter marked by soft demand, primarily due to elections, weather-related factors, and continued pressure on pricing. Despite these challenges, the focus remained on strategic priorities such as value optimization, cost optimization, and operational efficiency. The Company has reached the lowest blended fuel cost in the last 11 quarters at ₹ 1.57/ Mcal. The SAP unification program was successfully completed in line with the Company’s digital transformation journey, enabling us to streamline processes across the organization. Additionally, railway siding projects in Odisha and Sonadih are at an advanced stage of completion, which will further improve efficiency and profitability.</p>

          <p>He further added, “Looking ahead, the timing and pace of demand recovery will depend on the onground execution of infrastructure and housing projects. In the near term, headwinds primarily stem from the demand-supply imbalance and continued pricing pressure. To address these challenges, the Company will continue to focus on value optimization, premiumization, geo-optimization, brand strengthening, along with a strong emphasis on cost optimization.”</p>
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building materials company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest cement group in terms of capacity (25 MMTPA), with INR 10,733 crore in total revenue from operations in FY24. It is among the leading players in East India. Nuvoco offers a diversified business portfolio in three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes - Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses a pan-India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the firstof-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium (Rourkela), Aquatic Gallery Science City (Ahmedabad), Metro Railway (Delhi, Jaipur, Noida and Mumbai), and among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ brands, comprises construction chemicals, tile adhesives, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p> 
          <p>Know more about the Company on <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default FinancialResultQ1FY25